<template>
    <div>
        <b-card no-body class="card-app-design mb-0 bb-lrr-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Date Types" label-for="role">
                            <v-select class="text-capitalize" v-model="dateType"
                                      label="type" :options="['Daily','Hourly','Hourly 24']" :clearable="false"/>
                        </b-form-group>

                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Date Interval" label-for="dateInterval" v-if="dateType === 'Daily'">
                            <flat-pickr v-model="searchIntervalDate"
                                        :config="{mode: 'range',defaultDate:[searchReportDiffMonthDate,searchReportDate]}"
                                        class="form-control" placeholder="YYYY-MM-DD | YYYY-MM-DD" :clearable="true"/>
                        </b-form-group>
                        <b-form-group label="Date" label-for="date" v-else>
                            <flat-pickr v-model="searchDate" class="form-control" :clearable="true"
                                        key="1"
                                        placeholder="Select Date"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Event Types" label-for="role">
                            <v-select
                                v-model="eventFilter"
                                :reduce="(option) => option.value"
                                label="label"
                                :options="eventTypes"
                                :clearable="false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Role" label-for="role">
                            <v-select
                                v-model="roleFilter"
                                :reduce="(option) => option.value"
                                label="label"
                                :options="roleOptions"
                                :clearable="false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2" class="mb-md-0 mb-2">
                        <b-form-group label="Team" label-for="team">
                            <v-select v-if="teamList"
                                      :options="teamList" :reduce="(option) => option.value"
                                      v-model="teamFilterUser"
                                      placeholder="Select Team" class="w-100"/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2 my-auto" v-if="unityUsers">
                        <div class="design-planning-wrapper">
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Apps
                                </p>
                                <h6 class="mb-0">
                                    {{ unityUsers.data.total_app }}
                                </h6>
                            </div>
                            <div class="design-planning mb-0">
                                <p class="card-text mb-25">
                                    Member
                                </p>
                                <h6 class="mb-0">
                                    {{ unityUsers.data.users.length }}
                                </h6>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body id="guruTableScroll" class="mb-0 mt-0" v-if="!isLoading">
            <div class="guru" v-if="unityUsers">
                <div class="guru_row guru_row-hours sticky"
                     :style="'grid-template-columns: 150px repeat('+unityUsers.data.grid+', 1fr);'">
                    <div class="guru_row-first  d-flex  align-items-center">Name</div>
                    <span v-if="dateType === 'Daily'" v-for="date in unityUsers.data.periods"
                          :key="date.id">{{ formatDailyDate(date) }}</span>
                    <span v-if="dateType === 'Hourly' || dateType === 'Hourly 24'" v-for="date in unityUsers.data.periods"
                          :key="date.id">{{ formatHourlyDate(date) }}</span>
                </div>
                <div class="guru_row guru_row-lines"
                     :style="'grid-template-columns: 150px repeat('+unityUsers.data.grid+', 1fr);'">
                    <span></span>
                    <span v-for="date in unityUsers.data.periods" :key="date.id"></span>
                </div>
                <div class="guru_row" v-for="userlist in sortedUnityUsers" :key="userlist.id"
                     ref="refDiscordUsers">
                    <div class="guru_row-first" @click="gotoDetailView(userlist)">
                        <b-media vertical-align="center">
                            <template #aside>
                                <b-avatar size="26" :src="userlist.image" :text="avatarText(userlist.name)"/>
                            </template>
                            <b-link class="font-weight-bold d-block text-nowrap" >
                                {{ userlist.name }}
                            </b-link>
                            <small class="text-muted">Score: {{ userlist.total_event }}</small>
                        </b-media>
                    </div>

                    <ul class="guru_row-bars p-0"
                        :style="'grid-template-columns: repeat('+unityUsers.data.grid+', 1fr)!important;'">
                        <li v-for="(event,index) in userlist.events.filter(item => item.count > 0)" :key="index"
                            class="m-20 justify-content-center"
                            @click="event.count>0?getDetailInfo(userlist,event.date,dateType):null"
                            :style="dateType === 'Daily' ? dailyColumnFormat(event.key,event.count,userlist.total_event) : hourlyColumnFormat(event.key,event.count,userlist.total_event)"
                            v-b-tooltip.html.hover.top="
                               `${eventFilter === null ? `Total Score: ${event.count} <br/>` : ''}
                                ${event.script_reload !== 0 ? `Script Reload: ${event.script_reload} <br/>` : '' }
                                ${event.project_changed !== 0 ? `Project Changed: ${event.project_changed} <br/>`: ''}
                                ${event.hierarchy_changed !== 0 ? `Hierarchy Changed: ${event.hierarchy_changed} <br/>`: ''}
                                ${event.asset_saved !== 0 ? `Asset Saved: ${event.asset_saved} <br/>`: ''}
                                Date: ${dateType === 'Daily' ? event.date : `${event.date}:00 - ${event.date}:59`} <br/>
                               `">
                            {{ event.count }}
                        </li>
                    </ul>
                </div>
            </div>
            <unity-user-detail ref="modal" :modalDate="modalDate" :dateTypes="dateTypes"></unity-user-detail>
        </b-card>
        <b-card v-if="isLoading" class="mt-2">
            <div class="text-center my-2">
                <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-card>

        <b-card v-if="!isLoading && !unityUsers" class="mt-2">
            <div class="d-flex align-items-center">
                <strong>Not Found.</strong>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BOverlay, BFormGroup, BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {avatarText} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import useUnityApi from '@/composables/useUnityApi'
import moment from 'moment'
import UnityUserDetail from '@/components/UnityUserDetail.vue'

export default {
    components: {
        vSelect,
        BFormGroup,
        BSpinner,
        BOverlay,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        flatPickr,
        UnityUserDetail
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    data() {
        return {
            modalDate: null,
            dateTypes: null
        }
    },
    setup() {
        const {
            unityUsers,
            fetchUnityUsers,
            isLoading,
            refunityUsers,
            searchReportDate,
            searchIntervalDate,
            fetchDetailView,
            searchReportDiffMonthDate,
            fetchUnityUserInfo,
            modalLoading,
            roleOptions,
            roleFilter,
            eventFilter,
            teamFilterUser,
            teamList,
            fetchTeamList,
            eventTypes,
            dateType,
            searchDate
        } = useUnityApi()
        return {
            unityUsers,
            fetchUnityUsers,
            isLoading,
            refunityUsers,
            searchReportDate,
            searchIntervalDate,
            fetchDetailView,
            searchReportDiffMonthDate,
            avatarText,
            fetchUnityUserInfo,
            modalLoading,
            roleOptions,
            roleFilter,
            eventFilter,
            teamFilterUser,
            teamList,
            fetchTeamList,
            eventTypes,
            dateType,
            searchDate
        }
    },
    mounted() {
        this.fetchUnityUsers()
        this.fetchTeamList()
    },
    methods: {
        gotoDetailView(userlist) {
            this.$router.push({name: 'unity-detail', params: {userid: userlist.id}})
        },
        formatDailyDate(date) {
            return moment(date).format('DD.MM')
        },
        formatHourlyDate(date) {
            return moment(date).format('HH:mm')
        },
        dailyColumnFormat(key, count) {
            if (count === 0) return 'grid-column:' + key + ';background:none'
            let normalize = (count - 0) / (2000)
            let colorText = normalize < 0.5 ? "#232323" : '#f7f7f7'
            let background = `rgb(9, 191, 44, ${normalize})`;
            return `grid-column:${key};color:${colorText};background:${background}`
        },
        hourlyColumnFormat(key, count) {
            if (count === 0) return 'grid-column:' + key + ';background:none'
            let normalize = (count - 0) / (100)
            let colorText = normalize < 0.5 ? "#232323" : '#f7f7f7'
            let background = `rgb(9, 191, 44, ${normalize})`;
            return `grid-column:${key};color:${colorText};background:${background}`
        },
        getDetailInfo(user, date, dateTypes) {
            this.modalDate = date
            this.dateTypes = dateTypes
            this.fetchUnityUserInfo(user.id, date)
            this.$root.$emit("bv::show::modal", "modal-primary")
        },
    },
    computed: {
        sortedUnityUsers: function () {
            return this.unityUsers.data.users.sort((a, b) => a.name.localeCompare(b.name));
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.tooltip-inner {
    text-align: left;
}
</style>
